import * as yup from 'yup';

export enum PageVariantEnum {
  INITIAL,
  LOGIN,
  REGISTER,
  REGISTER_FILL_INFO,
  FORGOT_PASSWORD,
}

export const AUTH_FORM_FIELDS = {
  email: 'email',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  phoneNumber: 'phoneNumber',
  smsCode: 'smsCode',
  firstName: 'firstName',
  lastName: 'lastName',
  nickname: 'nickname',
  rememberMe: 'rememberMe',
  page: 'page',
  isSpecialist: 'isSpecialist',
  specialistPasscode: 'specialistPasscode',
} as const;

export const AUTH_FORM_FIELDS_INITIAL_VALUES = {
  email: '',
  password: '',
  passwordConfirmation: '',
  phoneNumber: '',
  smsCode: '',
  firstName: '',
  lastName: '',
  nickname: '',
  rememberMe: false,
  page: PageVariantEnum.INITIAL,
  isSpecialist: false,
  specialistPasscode: '',
};

export const getInitialValues = (locale: string, isPromo = false) => ({
  ...AUTH_FORM_FIELDS_INITIAL_VALUES,
  isSpecialist: isPromo,
  specialistPasscode: '',
  phoneNumber: (() => {
    if (locale === 'cs') return '+420';
    if (locale === 'en') return '+44';
    if (locale === 'sk') return '+421';
    return '';
  })()
});


export const AUTH_VALIDATION = yup.object({
  page: yup
    .number(),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Email must be a valid email')
    .max(255, 'Email must be at most 255 characters')
    .min(3, 'Email must be at least 3 characters'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(255, 'Password must be at most 255 characters')
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .when('page', {
      is: PageVariantEnum.REGISTER,
      then: (schema) => schema.required('Repeat password is required'),
    }),
  firstName: yup
    .string()
    .trim()
    .min(1, 'Password must be at least 3 characters')
    .when('page', {
      is: PageVariantEnum.REGISTER_FILL_INFO,
      then: (schema) => schema.required('First name is required'),
    }),
  lastName: yup
    .string()
    .trim()
    .min(1, 'Password must be at least 3 characters')
    .when('page', {
      is: PageVariantEnum.REGISTER_FILL_INFO,
      then: (schema) => schema.required('Last name is required'),
    }),
  nickname: yup
    .string()
    .trim()
    .min(3, 'Password must be at least 3 characters')
    .when('page', {
      is: PageVariantEnum.REGISTER_FILL_INFO,
      then: (schema) => schema.required('Nickname is required'),
    }),
  smsCode: yup
    .string()
    .when('page', {
      is: PageVariantEnum.FORGOT_PASSWORD,
      then: (schema) => schema.required('SMS code is required'),
    }),
}).required();